<template>
  <table-overview
    title="Submissions"
    icon="far fa-fw fa-globe"
    store="website/submissions"
    :headers="[
      { text: 'Completed at', value: 'completed_at' },
      { text: 'Name', value: 'full_name', sortable: false },
      { text: 'Website', value: 'website', sortable: false },
      { text: 'Subjects', value: 'subjects', sortable: false },
      { text: 'Leads', value: 'leads', sortable: false },
      { text: 'Status', value: 'id', sortable: false },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'submission_view'
      }
    ]"
    force-initial-sort="completed_at"
    descending
  >
    <website-submission-table-row
      slot="table-row"
      slot-scope="{ item }"
      :submission-id="item"
      :website-id="id"
    />
    <website-submission-table-filter slot="filters" />
  </table-overview>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

import TableOverview from "@/components/generic/overview/TableOverview";
import WebsiteSubmissionTableRow from "@/components/website/WebsiteSubmissionTableRow";
import WebsiteSubmissionTableFilter from "@/components/website/WebsiteSubmissionTableFilter";

const { mapGetters } = createNamespacedHelpers("website");
const { mapGetters: mapSubmissionGetters } = createNamespacedHelpers(
  "website/submissions"
);

export default {
  name: "website-submissions-view",
  components: {
    WebsiteSubmissionTableFilter,
    WebsiteSubmissionTableRow,
    TableOverview
  },
  data: () => ({
    triggerGroupMatchData: undefined
  }),
  computed: {
    ...mapGetters(["id"]),
    ...mapSubmissionGetters([FIND_BY_ID])
  },
  methods: {
    submission(appliedId) {
      return this[FIND_BY_ID](appliedId).submission;
    }
  }
};
</script>
<style scoped>
.info-text {
  margin-bottom: 2px;
}
</style>
