<template>
  <v-form>
    <v-select-locale v-model="locales" multiple />
    <v-checkbox
      v-model="hasProblems"
      label="Has problems"
      color="primary"
      hide-details
    />
    Select a timeframe to view the data:
    <v-flex xs12 class="pa-0">
      <v-btn-toggle v-model="timeframe" mandatory depressed>
        <v-btn value="W" outline depressed color="primary">
          W
        </v-btn>
        <v-btn value="M" outline depressed color="primary">
          M
        </v-btn>
        <v-btn value="3M" outline depressed color="primary">
          3M
        </v-btn>
        <v-btn value="Y" outline depressed color="primary">
          Y
        </v-btn>
        <v-btn value="ALL" outline depressed color="primary">
          ALL
        </v-btn>
      </v-btn-toggle>
    </v-flex>
    <v-flex>
      <v-subheader class="pl-0">Minimum visitors:</v-subheader>
      <v-layout row>
        <v-flex shrink style="width: 75px">
          <v-text-field
            v-model="minVisitors"
            class="mt-0"
            hide-details
            single-line
            type="number"
          ></v-text-field>
        </v-flex>
        <v-flex class="px-2">
          <v-slider
            v-model="minVisitors"
            thumb-label="always"
            max="1000"
          ></v-slider>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-form>
</template>
<script type="text/babel">
import VSelectLocale from "../locale/VSelectLocale";
import { cloneDeep } from "lodash";
import { createNamespacedHelpers } from "@/store/helpers";

const { mapComputedFilters } = createNamespacedHelpers("websites");

export default {
  name: "websites-overview-filters",
  components: {
    VSelectLocale
  },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  data: () => ({
    toggle_timeframe: "3M"
  }),
  computed: {
    ...mapComputedFilters([
      "locales",
      "hasProblems",
      "allTimeMinVisitors",
      "lastYearMinVisitors",
      "lastThreeMonthsMinVisitors",
      "lastMonthMinVisitors",
      "lastWeekMinVisitors"
    ]),
    timeframe: {
      get() {
        return this.toggle_timeframe;
      },
      set(newVal) {
        this.$emit("set-timeframe", newVal);
        this.allTimeMinVisitors = null;
        this.lastYearMinVisitors = null;
        this.lastThreeMonthsMinVisitors = null;
        this.lastMonthMinVisitors = null;
        this.lastWeekMinVisitors = null;
        this.toggle_timeframe = newVal;
      }
    },
    minVisitors: {
      get() {
        if (this.timeframe === "M") {
          return cloneDeep(this.lastMonthMinVisitors);
        } else if (this.timeframe === "W") {
          return cloneDeep(this.lastWeekMinVisitors);
        } else if (this.timeframe === "3M") {
          return cloneDeep(this.lastThreeMonthsMinVisitors);
        } else if (this.timeframe === "Y") {
          return cloneDeep(this.lastYearMinVisitors);
        } else if (this.timeframe === "ALL") {
          return cloneDeep(this.allTimeMinVisitors);
        } else {
          return 0;
        }
      },
      set(val) {
        if (isNaN(val)) {
          val = 0;
        }
        if (this.timeframe === "M") {
          this.lastMonthMinVisitors = val;
        } else if (this.timeframe === "W") {
          this.lastWeekMinVisitors = val;
        } else if (this.timeframe === "3M") {
          this.lastThreeMonthsMinVisitors = val;
        } else if (this.timeframe === "Y") {
          this.lastYearMinVisitors = val;
        } else if (this.timeframe === "ALL") {
          this.allTimeMinVisitors = val;
        }
      }
    }
  }
};
</script>
