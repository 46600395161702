<template>
  <div>
    <div class="pa-4">
      <website-tags />
      <website-information />
      <website-note />
    </div>
    <div style="text-align: center;">
      <h1>Website Statistics</h1>
      <p class="date">{{ from }} - {{ to }}</p>
      <p class="date-last-year">{{ oneYearAgoFrom }} - {{ oneYearAgoTo }}</p>
    </div>
    <v-layout justify-center row>
      <v-flex xs2 class="py-2 text-xs-center">
        <v-btn-toggle v-model="chartType">
          <v-btn flat>
            Total
          </v-btn>
          <v-btn flat>
            Device
          </v-btn>
        </v-btn-toggle>
      </v-flex>
      <v-flex xs2 class="py-2 text-xs-center">
        <v-btn-toggle v-model="intervalType">
          <v-btn flat value="monthly">
            monthly
          </v-btn>
          <v-btn flat value="daily">
            daily
          </v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-layout>
    <div class="tw-mx-8 tw-grid tw-grid-cols-4">
      <v-checkbox
        v-for="sibling in siblings"
        v-model="selected"
        :key="sibling.id"
        :label="`${sibling.host}`"
        :value="sibling.id"
      ></v-checkbox>
    </div>
    <website-total-stats-view
      v-if="chartType === 0"
      key="totalStats"
      :selected-siblings="selected"
      :interval="intervalType"
    />
    <website-device-stats-view
      v-else
      :selected-siblings="selected"
      key="deviceStats"
      :interval="intervalType"
    />
    <website-annotations-dashboard class="tw-mt-10 tw-mx-7" />
    <website-annotation-dialog
      :create-annotation-dialog="annotationDialog"
      :website-id="[website.id]"
      @close="annotationDialog = false"
    />
    <v-speed-dial
      v-model="fab"
      :top="false"
      :bottom="true"
      :right="true"
      :left="false"
      fixed
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <v-btn slot="activator" color="blue darken-2" dark fab>
        <v-icon>more_vert</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-tooltip
        v-for="(action, index) in actions"
        :key="index"
        :disabled="tooltipsDisabled"
        left
        :value="tooltips"
      >
        <v-btn
          v-if="hasRolesOrPermissions(action.rolesOrPermissions)"
          slot="activator"
          fab
          dark
          small
          :color="action.color"
          :to="action.to"
          @click.native="action.clickFn"
        >
          <v-icon>{{ action.icon }}</v-icon>
        </v-btn>
        <span>{{ action.tag }}</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import WebsiteAnnotationsDashboard from "@/components/website/WebsiteAnnotationsDashboard";
import WebsiteTags from "../../components/website/dashboard/WebsiteTags";
import WebsiteInformation from "../../components/website/dashboard/WebsiteInformation";
import WebsiteNote from "@/components/website/dashboard/WebsiteNote";
import WebsiteAnnotationDialog from "@/views/websites/WebsiteAnnotationDialog";
import moment from "moment";
import WebsiteTotalStatsView from "./WebsiteTotalStatsView";
import WebsiteDeviceStatsView from "./WebsiteDeviceStatsView";
const { mapGetters } = createNamespacedHelpers("website");
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "website-dashboard-view",
  components: {
    WebsiteDeviceStatsView,
    WebsiteTotalStatsView,
    WebsiteAnnotationDialog,
    WebsiteNote,
    WebsiteInformation,
    WebsiteTags,
    WebsiteAnnotationsDashboard
  },
  data() {
    return {
      selected: [],
      actions: [
        {
          icon: "far fa-fw fa-edit",
          tag: "create new annotation",
          color: "pink",
          // to: {name: 'annotations.create', params: {websiteIds: parseInt(this.$route.params.websiteId)}}
          clickFn: this.createAnnotationDialog,
          rolesOrPermissions: "annotation_create"
        }
      ],
      fab: false,
      tooltips: false,
      tooltipsDisabled: false,
      annotationDialog: false,
      chartType: 0,
      intervalType: "monthly",
      to: moment()
        .subtract(1, "days")
        .format("DD-MM-YYYY"),
      from: moment()
        .subtract(1, "years")
        .startOf("month")
        .format("DD-MM-YYYY"),
      oneYearAgoFrom: moment()
        .subtract(2, "years")
        .startOf("month")
        .format("YYYY-MM-DD"),
      oneYearAgoTo: moment()
        .subtract(1, "years")
        .endOf("month")
        .format("YYYY-MM-DD")
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapGetters(["website", "siblings"]),
    annotations() {
      return this.website ? this.website.annotations : [];
    }
  },
  watch: {
    fab(val) {
      this.tooltips = false;
      this.tooltipsDisabled = false;
      val &&
        setTimeout(() => {
          this.tooltips = true;
          this.$nextTick(() => (this.tooltipsDisabled = true));
        }, 100);
    },
    intervalType(newVal) {
      if (newVal === "monthly") {
        this.to = moment()
          .subtract(1, "days")
          .format("DD-MM-YYYY");
        this.from = moment()
          .subtract(1, "years")
          .startOf("month")
          .format("DD-MM-YYYY");
        this.oneYearAgoFrom = moment()
          .subtract(2, "years")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.oneYearAgoTo = moment()
          .subtract(1, "years")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        this.to = moment()
          .subtract(1, "days")
          .format("DD-MM-YYYY");
        this.from = moment()
          .subtract(3, "months")
          .startOf("month")
          .format("DD-MM-YYYY");
        this.oneYearAgoFrom = moment()
          .subtract(1, "years")
          .subtract(3, "months")
          .startOf("month")
          .format("DD-MM-YYYY");
        this.oneYearAgoTo = moment()
          .subtract(1, "years")
          .endOf("month")
          .format("DD-MM-YYYY");
      }
    }
  },
  methods: {
    sibling(id) {
      return this.$store.getters["website/sibling"](id);
    },
    createAnnotationDialog() {
      this.$store.commit("website/openAnnotationDialog", true);
    }
  }
};
</script>
<style scoped>
.date {
  text-align: center;
  color: darkgray;
  margin-bottom: 0px;
}
.date-last-year {
  text-align: center;
  color: darkgray;
  font-size: 12px;
}
</style>
