<template>
  <div>
    <v-card class="my-4">
      <div style="height: 200px">
        <vue-apex-charts
          ref="websiteTrafficChartDesktop"
          height="100%"
          type="line"
          :options="optionsDesktop"
          :series="seriesDesktop"
        />
      </div>
      <v-layout>
        <v-flex xs6>
          <v-layout
            justify-space-between
            style="padding-left: 20px; padding-right: 20px"
          >
            <v-flex>
              Average Visitors: <br />
              <b style="font-size: 18px;">{{ averageDesktopVisitors }}</b>
            </v-flex>
            <v-flex xs6 style="text-align: end; color: gray;">
              Average last year: <br />
              <b style="font-size: 18px;">{{
                lastYearAverageDesktopVisitors
              }}</b>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout
            justify-space-between
            style="padding-left: 20px; padding-right: 20px"
          >
            <v-flex>
              Average bounce rate: <br />
              <b style="font-size: 18px;">{{ averageDesktopBounceRate }}%</b>
            </v-flex>
            <v-flex xs6 style="text-align: end; color: gray;">
              Average last year: <br />
              <b style="font-size: 18px;"
                >{{ lastYearAverageDesktopBounceRate }}%</b
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card class="mb-4">
      <div style="height: 200px">
        <vue-apex-charts
          ref="websiteTrafficChartPhone"
          height="100%"
          type="line"
          :options="optionsPhone"
          :series="seriesPhone"
        />
      </div>
      <v-layout>
        <v-flex xs6>
          <v-layout
            justify-space-between
            style="padding-left: 20px; padding-right: 20px"
          >
            <v-flex>
              Average Visitors: <br />
              <b style="font-size: 18px;">{{ averagePhoneVisitors }}</b>
            </v-flex>
            <v-flex xs6 style="text-align: end; color: gray;">
              Average last year: <br />
              <b style="font-size: 18px;">{{ lastYearAveragePhoneVisitors }}</b>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout
            justify-space-between
            style="padding-left: 20px; padding-right: 20px"
          >
            <v-flex>
              Average bounce rate: <br />
              <b style="font-size: 18px;">{{ averagePhoneBounceRate }}%</b>
            </v-flex>
            <v-flex xs6 style="text-align: end; color: gray;">
              Average last year: <br />
              <b style="font-size: 18px;"
                >{{ lastYearAveragePhoneBounceRate }}%</b
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card class="mb-4">
      <div style="height: 200px">
        <vue-apex-charts
          ref="websiteTrafficChartTablet"
          height="100%"
          type="line"
          :options="optionsTablet"
          :series="seriesTablet"
        />
      </div>
      <v-layout>
        <v-flex xs6>
          <v-layout
            justify-space-between
            style="padding-left: 20px; padding-right: 20px"
          >
            <v-flex>
              Average Visitors: <br />
              <b style="font-size: 18px;">{{ averageTabletVisitors }}</b>
            </v-flex>
            <v-flex xs6 style="text-align: end; color: gray;">
              Average last year: <br />
              <b style="font-size: 18px;">{{
                lastYearAverageTabletVisitors
              }}</b>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout
            justify-space-between
            style="padding-left: 20px; padding-right: 20px"
          >
            <v-flex>
              Average bounce rate: <br />
              <b style="font-size: 18px;">{{ averageTabletBounceRate }}%</b>
            </v-flex>
            <v-flex xs6 style="text-align: end; color: gray;">
              Average last year: <br />
              <b style="font-size: 18px;"
                >{{ lastYearAverageTabletBounceRate }}%</b
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import { isNil, cloneDeep } from "lodash";
import moment from "moment";

export default {
  name: "website-device-traffic-graph",
  components: {
    vueApexCharts
  },
  props: {
    stats: {
      type: Array,
      default: () => []
    },
    lastYearStats: {
      type: Array,
      default: () => []
    },
    averageData: {
      type: Object,
      default: () => {}
    },
    lastYearAverageData: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: "Traffic"
    },
    interval: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      averageDesktopVisitors: null,
      averagePhoneVisitors: null,
      averageTabletVisitors: null,
      averageDesktopBounceRate: null,
      averagePhoneBounceRate: null,
      averageTabletBounceRate: null,
      lastYearAverageDesktopVisitors: null,
      lastYearAveragePhoneVisitors: null,
      lastYearAverageTabletVisitors: null,
      lastYearAverageDesktopBounceRate: null,
      lastYearAveragePhoneBounceRate: null,
      lastYearAverageTabletBounceRate: null,
      seriesDesktop: [
        {
          name: "Visitors",
          type: "line",
          data: []
        },
        {
          name: "Last year Visitors",
          type: "line",
          data: []
        },
        {
          name: "Bounce rate",
          type: "line",
          data: []
        },
        {
          name: "Last year Bounce rate",
          type: "line",
          data: []
        }
      ],
      seriesPhone: [
        {
          name: "Visitors",
          type: "line",
          data: []
        },
        {
          name: "Last year Visitors",
          type: "line",
          data: []
        },
        {
          name: "Bounce rate",
          type: "line",
          data: []
        },
        {
          name: "Last year Bounce rate",
          type: "line",
          data: []
        }
      ],
      seriesTablet: [
        {
          name: "Visitors",
          type: "line",
          data: []
        },
        {
          name: "Last year Visitors",
          type: "line",
          data: []
        },
        {
          name: "Bounce rate",
          type: "line",
          data: []
        },
        {
          name: "Last year Bounce rate",
          type: "line",
          data: []
        }
      ]
    };
  },
  watch: {
    stats: {
      handler(newVal) {
        this.defineAverages(newVal);
        this.seriesDesktop.forEach(e => {
          e.data = [];
        });
        this.seriesPhone.forEach(e => {
          e.data = [];
        });
        this.seriesTablet.forEach(e => {
          e.data = [];
        });
        newVal.forEach(e => {
          this.seriesDesktop[0].data.push([
            new Date(e.date).getTime(),
            parseInt(e.desktopPageviews)
          ]);
          this.seriesDesktop[2].data.push([
            new Date(e.date).getTime(),
            e.desktopBounceRate
          ]);

          this.seriesPhone[0].data.push([
            new Date(e.date).getTime(),
            parseInt(e.phonePageviews)
          ]);
          this.seriesPhone[2].data.push([
            new Date(e.date).getTime(),
            e.phoneBounceRate
          ]);

          this.seriesTablet[0].data.push([
            new Date(e.date).getTime(),
            parseInt(e.tabletPageviews)
          ]);
          this.seriesTablet[2].data.push([
            new Date(e.date).getTime(),
            e.tabletBounceRate
          ]);
        });
      },
      deep: true
    },
    lastYearStats: {
      handler(newVal) {
        this.defineLastYearAverages(newVal);
        let mappedVal = cloneDeep(newVal);
        mappedVal = mappedVal.map(data => {
          data.date = moment(data.date)
            .add(1, "years")
            .format(this.interval === "monthly" ? "YYYY-MM" : "YYYY-MM-DD");
          return data;
        });
        this.seriesDesktop[1].data = [];
        this.seriesPhone[1].data = [];
        this.seriesTablet[1].data = [];

        this.seriesDesktop[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          if (!isNil(equivalentLastYear)) {
            this.seriesDesktop[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.desktopPageviews
            ]);
            this.seriesDesktop[3].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.desktopBounceRate
            ]);
          } else {
            this.seriesDesktop[1].data.push([data[0], 0]);
            this.seriesDesktop[3].data.push([data[0], 0]);
          }
        });

        this.seriesPhone[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          if (!isNil(equivalentLastYear)) {
            this.seriesPhone[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.phonePageviews
            ]);
            this.seriesPhone[3].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.phoneBounceRate
            ]);
          } else {
            this.seriesPhone[1].data.push([data[0], 0]);
            this.seriesPhone[3].data.push([data[0], 0]);
          }
        });

        this.seriesTablet[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          if (!isNil(equivalentLastYear)) {
            this.seriesTablet[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.tabletPageviews
            ]);
            this.seriesTablet[3].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.tabletBounceRate
            ]);
          } else {
            this.seriesTablet[1].data.push([data[0], 0]);
            this.seriesTablet[3].data.push([data[0], 0]);
          }
        });
      },
      deep: true
    }
  },
  computed: {
    optionsDesktop() {
      return {
        chart: {
          id: "traffic",
          group: "metrics",
          height: "100%",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5",
          animations: {
            enabled: false
          }
        },
        stroke: {
          width: 2,
          dashArray: [0, 8, 0, 8]
        },
        title: {
          text: "Desktop " + this.title,
          align: "center",
          margin: 10,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        markers: {
          size: 3,
          hover: {
            size: 6
          }
        },
        colors: ["#0653A6", "#0653A6", "#FFCD00", "#FFCD00"],
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Visitors"
            }
          },
          {
            show: false,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Landing Visitors"
            }
          },
          {
            show: false,
            seriesName: "Bounce rate",
            title: {
              text: "Bounce rate"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: value => {
                return `${this.round(value)}%`;
              }
            }
          },
          {
            show: false,
            seriesName: "Bounce rate",
            title: {
              text: "Bounce rate"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: value => {
                return `${this.round(value)}%`;
              }
            }
          }
        ]
      };
    },
    optionsPhone() {
      return {
        chart: {
          id: "traffic",
          group: "metrics",
          height: "100%",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5",
          animations: {
            enabled: false
          }
        },
        stroke: {
          width: 2,
          dashArray: [0, 8, 0, 8]
        },
        title: {
          text: "Phone " + this.title,
          align: "center",
          margin: 10,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        markers: {
          size: 3,
          hover: {
            size: 6
          }
        },
        colors: ["#0653A6", "#0653A6", "#FFCD00", "#FFCD00"],
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Visitors"
            }
          },
          {
            show: false,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Landing Visitors"
            }
          },
          {
            show: false,
            seriesName: "Bounce rate",
            title: {
              text: "Bounce rate"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: value => {
                return `${this.round(value)}%`;
              }
            }
          },
          {
            show: false,
            seriesName: "Bounce rate",
            title: {
              text: "Bounce rate"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: value => {
                return `${this.round(value)}%`;
              }
            }
          }
        ]
      };
    },
    optionsTablet() {
      return {
        chart: {
          id: "traffic",
          group: "metrics",
          height: "100%",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5",
          animations: {
            enabled: false
          }
        },
        stroke: {
          width: 2,
          dashArray: [0, 8, 0, 8]
        },
        title: {
          text: "Tablet " + this.title,
          align: "center",
          margin: 10,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        markers: {
          size: 3,
          hover: {
            size: 6
          }
        },
        colors: ["#0653A6", "#0653A6", "#FFCD00", "#FFCD00"],
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Visitors"
            }
          },
          {
            show: false,
            seriesName: "Visitors",
            decimalsInFloat: false,
            title: {
              text: "Landing Visitors"
            }
          },
          {
            show: false,
            seriesName: "Bounce rate",
            title: {
              text: "Bounce rate"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: value => {
                return `${this.round(value)}%`;
              }
            }
          },
          {
            show: false,
            seriesName: "Bounce rate",
            title: {
              text: "Bounce rate"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: value => {
                return `${this.round(value)}%`;
              }
            }
          }
        ]
      };
    }
  },
  methods: {
    round(rate, amount = 2) {
      return !isNil(rate) ? rate.toFixed(amount) : rate;
    },
    defineAverages(series) {
      this.averageDesktopVisitors = this.getAvg(
        series.map(serie => serie.desktopPageviews)
      );
      this.averagePhoneVisitors = this.getAvg(
        series.map(serie => serie.phonePageviews)
      );
      this.averageTabletVisitors = this.getAvg(
        series.map(serie => serie.tabletPageviews)
      );

      this.averageDesktopBounceRate = this.getAvg(
        series.map(serie => serie.desktopBounceRate)
      );
      this.averagePhoneBounceRate = this.getAvg(
        series.map(serie => serie.phoneBounceRate)
      );
      this.averageTabletBounceRate = this.getAvg(
        series.map(serie => serie.tabletBounceRate)
      );
    },
    defineLastYearAverages(series) {
      this.lastYearAverageDesktopVisitors = this.getAvg(
        series.map(serie => serie.desktopPageviews)
      );
      this.lastYearAveragePhoneVisitors = this.getAvg(
        series.map(serie => serie.phonePageviews)
      );
      this.lastYearAverageTabletVisitors = this.getAvg(
        series.map(serie => serie.tabletPageviews)
      );

      this.lastYearAverageDesktopBounceRate = this.getAvg(
        series.map(serie => serie.desktopBounceRate)
      );
      this.lastYearAveragePhoneBounceRate = this.getAvg(
        series.map(serie => serie.phoneBounceRate)
      );
      this.lastYearAverageTabletBounceRate = this.getAvg(
        series.map(serie => serie.tabletBounceRate)
      );
    },
    getAvg(grades) {
      grades.pop();
      const total = grades.reduce((acc, c) => acc + c, 0);
      return (total / grades.length).toFixed(0);
    }
  }
};
</script>
