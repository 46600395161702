<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <v-text-field
      slot="activator"
      v-model="formattedDate"
      :label="label"
      prepend-icon="far fa-calendar-alt"
      readonly
      clearable
      v-validate="required ? 'required' : ''"
      :error-messages="dataVvName ? errors.collect(dataVvName) : null"
      :data-vv-name="dataVvName"
    />
    <v-date-picker
      v-model="model"
      :type="internalType"
      first-day-of-week="1"
      @input="$refs.menu.save(model)"
    />
  </v-menu>
</template>

<script type="text/babel">
import moment from "moment";
export default {
  name: "b-date-picker",
  inject: ["$validator"],
  props: {
    label: {
      type: String,
      default: "Pick a date"
    },
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      optional: true
    },
    required: {
      type: Boolean,
      optional: true
    },
    dataVvName: {
      type: String,
      optional: true
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    model: {
      get() {
        if (!this.value) {
          return null;
        }
        return moment(this.value).format("YYYY-MM-DD");
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    internalType() {
      return ["month", "year"].includes(this.type) ? "month" : "date";
    },
    formattedDate: {
      get() {
        return this.value ? moment(this.value).format(this.dateFormat) : "";
      },
      set() {
        this.model = null;
      }
    },
    dateFormat() {
      switch (this.type) {
        case "month":
          return "MMM YYYY";
        case "year":
          return "YYYY";
        default:
          return "DD MMM YYYY";
      }
    }
  }
};
</script>
