import tableMutations from "../../templates/table/mutations";

export default {
  ...tableMutations,
  setSort(state, timeframe) {
    const options = [
      "all_time",
      "last_year",
      "last_three_months",
      "last_month",
      "last_week"
    ];

    let toAdd;
    switch (timeframe) {
      case "ALL":
        toAdd = "all_time";
        break;
      case "Y":
        toAdd = "last_year";
        break;
      case "3M":
        toAdd = "last_three_months";
        break;
      case "M":
        toAdd = "last_month";
        break;
      case "W":
        toAdd = "last_week";
        break;
      default:
        toAdd = "";
    }

    options.forEach(e => {
      state.pagination.sortBy = state.pagination.sortBy.replace(e, toAdd);
    });
  }
};
