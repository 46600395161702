<template>
  <v-card>
    <div style="height: 200px">
      <vue-apex-charts
        ref="websiteDeviceConversionsChart"
        height="100%"
        type="line"
        :options="options"
        :series="series"
      />
    </div>
    <v-layout>
      <v-flex xs4>
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex>
            Average Desktop: <br />
            <b style="font-size: 18px;">{{ averageDesktopConversions }}</b>
          </v-flex>
          <v-flex xs6 style="text-align: end; color: gray;">
            Last year average Desktop: <br />
            <b style="font-size: 18px;">{{
              lastYearAverageDesktopConversions
            }}</b>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4 style="background-color: #ededed">
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex>
            Average Phone: <br />
            <b style="font-size: 18px;">{{ averagePhoneConversions }}</b>
          </v-flex>
          <v-flex xs6 style="text-align: end; color: gray;">
            Last year average Phone: <br />
            <b style="font-size: 18px;">{{
              lastYearAveragePhoneConversions
            }}</b>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4>
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex>
            Average Tablet: <br />
            <b style="font-size: 18px;">{{ averageTabletConversions }}</b>
          </v-flex>
          <v-flex xs6 style="text-align: end; color: gray;">
            Last year average Tablet: <br />
            <b style="font-size: 18px;">{{
              lastYearAverageTabletConversions
            }}</b>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import { isNil, cloneDeep } from "lodash";
import moment from "moment";

export default {
  name: "website-device-total-conversions-stats",
  components: {
    vueApexCharts
  },
  props: {
    stats: {
      type: Array,
      default: () => []
    },
    lastYearStats: {
      type: Array,
      default: () => []
    },
    averageData: {
      type: Object,
      default: () => {}
    },
    lastYearAverageData: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: "Total Conversions"
    },
    interval: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      averageDesktopConversions: null,
      averagePhoneConversions: null,
      averageTabletConversions: null,
      lastYearAverageDesktopConversions: null,
      lastYearAveragePhoneConversions: null,
      lastYearAverageTabletConversions: null,
      series: [
        {
          name: "Desktop",
          type: "bar",
          data: []
        },
        {
          name: "Last Year Desktop",
          type: "bar",
          data: []
        },
        {
          name: "Phone",
          type: "bar",
          data: []
        },
        {
          name: "Last Year Phone",
          type: "bar",
          data: []
        },
        {
          name: "Tablet",
          type: "bar",
          data: []
        },
        {
          name: "Last Year Tablet",
          type: "bar",
          data: []
        }
      ]
    };
  },
  watch: {
    stats: {
      handler(newVal) {
        this.defineAverages(newVal);
        this.series.forEach(e => {
          e.data = [];
        });
        newVal.forEach(e => {
          this.series[0].data.push([
            new Date(e.date).getTime(),
            e.desktopConversions
          ]);
          this.series[2].data.push([
            new Date(e.date).getTime(),
            e.phoneConversions
          ]);
          this.series[4].data.push([
            new Date(e.date).getTime(),
            e.tabletConversions
          ]);
        });
      },
      deep: true
    },
    lastYearStats: {
      handler(newVal) {
        this.defineLastYearAverages(newVal);
        let mappedVal = cloneDeep(newVal);
        mappedVal = mappedVal.map(data => {
          data.date = moment(data.date)
            .add(1, "years")
            .format(this.interval === "monthly" ? "YYYY-MM" : "YYYY-MM-DD");
          return data;
        });
        this.series[1].data = [];
        this.series[3].data = [];
        this.series[5].data = [];

        this.series[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          if (!isNil(equivalentLastYear)) {
            this.series[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.desktopConversions
            ]);
            this.series[3].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.phoneConversions
            ]);
            this.series[5].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.tabletConversions
            ]);
          } else {
            this.series[1].data.push([data[0], 0.0]);
            this.series[3].data.push([data[0], 0.0]);
            this.series[5].data.push([data[0], 0.0]);
          }
        });
        setTimeout(this.disableLegends, 1000);
      },
      deep: true
    },
    annotations: {
      handler(newVal) {
        this.setAnnotations(newVal);
      },
      deep: true
    }
  },
  computed: {
    options() {
      return {
        chart: {
          id: "tc",
          group: "metrics",
          toolbar: {
            show: false
          },
          background: "#f5f5f5",
          animations: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          }
        },
        grid: {
          show: false
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        title: {
          text: this.title,
          align: "center",
          margin: 10,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        colors: [
          "#0653A6",
          "#5D8BBD",
          "#0AAA00",
          "#87DA82",
          "#FFCD00",
          "#FFE98E"
        ],
        xaxis: {
          type: "datetime"
        },
        yaxis: [
          {
            show: false,
            title: {
              text: "Conversions"
            },
            labels: {
              formatter: function(value) {
                return value;
              },
              minWidth: 0
            }
          }
        ]
      };
    }
  },
  methods: {
    round(rate, amount = 2) {
      return !isNil(rate) ? rate.toFixed(amount) : rate;
    },
    defineAverages(series) {
      this.averageDesktopConversions = this.getAvg(
        series.map(serie => serie.desktopConversions)
      );
      this.averagePhoneConversions = this.getAvg(
        series.map(serie => serie.phoneConversions)
      );
      this.averageTabletConversions = this.getAvg(
        series.map(serie => serie.tabletConversions)
      );
    },
    defineLastYearAverages(series) {
      this.lastYearAverageDesktopConversions = this.getAvg(
        series.map(serie => serie.desktopConversions)
      );
      this.lastYearAveragePhoneConversions = this.getAvg(
        series.map(serie => serie.phoneConversions)
      );
      this.lastYearAverageTabletConversions = this.getAvg(
        series.map(serie => serie.tabletConversions)
      );
    },
    getAvg(grades) {
      grades.pop();
      const total = grades.reduce((acc, c) => acc + c, 0);
      return (total / grades.length).toFixed(0);
    },
    disableLegends() {
      const chart = this.$refs.websiteDeviceConversionsChart;
      if (!isNil(chart)) {
        chart.toggleSeries("Last Year Desktop");
        chart.toggleSeries("Last Year Phone");
        chart.toggleSeries("Last Year Tablet");
      }
    }
  }
};
</script>
<style scoped></style>
