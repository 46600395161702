<template>
  <table-overview
    title="Articles"
    icon="far fa-fw fa-globe"
    store="website/articles"
    :headers="[
      { text: 'Path', value: 'path' },
      { text: 'Title', value: 'title' },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'article_view'
      }
    ]"
    searchable
    force-initial-sort
  >
    <website-articles-table-row
      slot="table-row"
      slot-scope="{ item }"
      :website-id="id"
      :article-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import TableOverview from "../../components/generic/overview/TableOverview";
import WebsiteArticlesTableRow from "../../components/website/WebsiteArticlesTableRow";

const { mapGetters } = createNamespacedHelpers("website");

export default {
  name: "websites-overview",
  components: {
    WebsiteArticlesTableRow,
    TableOverview
  },
  computed: {
    ...mapGetters(["id"])
  }
};
</script>
