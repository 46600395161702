<template>
  <tr :class="rowClass">
    <td>
      {{ localizeDateTime(submission.completedAt) | moment("from", "now") }}
    </td>
    <td>{{ submission.fullName }}</td>
    <td>{{ websiteHost }}</td>
    <td>
      <v-chip v-for="subject in subjects" :key="subject.id" label>
        {{ subject.name }}
      </v-chip>
    </td>
    <td>
      <website-submission-table-row-lead
        v-for="leadId in submission.leads"
        :key="leadId"
        :lead-id="leadId"
      />
    </td>
    <td>{{ status }}</td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'submissions.show',
          params: { submissionId: submission.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { DateTime } from "luxon";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import WebsiteSubmissionTableRowLead from "@/components/website/WebsiteSubmissionTableRowLead";

const { mapGetters } = createNamespacedHelpers("website/submissions");

export default {
  name: "website-submission-table-row",
  components: { WebsiteSubmissionTableRowLead },
  props: {
    submissionId: {
      type: Number,
      required: true
    },
    websiteId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([FIND_BY_ID]),
    submission() {
      return this.$store.state.website.submissions.entity[this.submissionId];
    },
    rowClass() {
      return {
        warning:
          !this.submission.isRejected &&
          (this.submission.isFlagged ||
            !this.submission.isGeocoded ||
            !this.submission.isRefined ||
            !this.submission.isValidated ||
            !this.submission.isSeparated),
        error: this.submission.isRejected
      };
    },
    country() {
      return this.$store.state.website.submissions.country[
        this.submission.country
      ];
    },
    countryName() {
      return this.country.name;
    },
    form() {
      return this.$store.state.website.submissions.form[this.submission.form];
    },
    formName() {
      return this.form.name;
    },
    website() {
      return this.$store.state.website.submissions.website[
        this.submission.website
      ];
    },
    websiteHost() {
      return this.website.host;
    },
    language() {
      return this.$store.state.website.submissions.language[
        this.submission.language
      ];
    },
    languageName() {
      return this.language.name;
    },
    subjects() {
      return Object.values(
        this.$store.state.website.submissions.subject
      ).filter(subject => this.submission.subjects.indexOf(subject.id) > -1);
    },
    isProcessed() {
      return (
        this.submission.isGeocoded &&
        this.submission.isRefined &&
        this.submission.isValidated &&
        this.submission.isSeparated
      );
    },
    status() {
      if (this.isProcessed) {
        return "Processed";
      } else if (this.submission.isSeparated) {
        return "Separated";
      } else if (this.submission.isValidated) {
        return "Validated";
      } else if (this.submission.isRefined) {
        return "Refined";
      } else if (this.submission.isGeocoded) {
        return "Geocoded";
      } else {
        return "NONE";
      }
    }
  },
  methods: {
    goToDetail({ id }) {
      this.$router.push({
        name: "submissions.show",
        params: { submissionId: id }
      });
    },
    localizeDateTime(timestamp) {
      return new DateTime.fromISO(timestamp).toFormat("yyyy-LL-dd HH:mm:ss");
    }
  }
};
</script>
