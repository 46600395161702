<script>
import { isNil } from "lodash";

export default {
  name: "offer-mixin",
  computed: {
    offerState() {
      if (isNil(this.offer)) return undefined;
      if (!isNil(this.offer.rejectedAt)) {
        return "REJECTED";
      } else if (
        !isNil(this.offer.rejectionRequestedAt) &&
        isNil(this.offer.rejectionDeniedAt)
      ) {
        return "REJECTION_REQUESTED";
      } else if (!isNil(this.offer.distributedAt)) {
        return "SENT";
      } else {
        return "UNSENT";
      }
    },
    offerStateContextClass() {
      if (isNil(this.offerState)) return undefined;
      switch (this.offerState) {
        case "REJECTED":
          return "error";
        case "REJECTION_REQUESTED":
          return "warning";
        case "SENT":
          return "success";
        case "UNSENT":
          return "grey";
        default:
          return null;
      }
    },
    offerStateContextDark() {
      if (isNil(this.offerState)) return false;
      switch (this.offerState) {
        case "REJECTED":
          return true;
        case "REJECTION_REQUESTED":
          return false;
        case "SENT":
          return true;
        case "UNSENT":
          return false;
        default:
          return false;
      }
    }
  }
};
</script>
