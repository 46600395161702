<template>
  <v-container fluid>
    <v-text-field v-model="title" label="Title" :counter="255" />
    <b-date-picker v-model="date" />
    <v-autocomplete-annotation-type
      v-model="type"
      disabled
      @data-loaded="typeLoaded"
    />
    <v-autocomplete-annotation-sub-type
      v-if="!isTypeNull"
      v-model="subTypes"
      :type="type"
      :reload-sub-type="reloadSubTypes"
      item-text="title"
      multiple
      @set-reload-sub-type="setReloadSubTypes"
    />
    <v-checkbox
      v-if="type === 3"
      v-model="addToWebsite"
      label="Add annotation to website"
    ></v-checkbox>
    <v-upload-image
      v-model="file"
      preview
      label="Annotation picture"
      placeholder="Click to upload a new annotation picture"
      :error-messages="errors.collect('files')"
    />
    <v-layout row>
      <v-flex v-for="(image, index) in images.slice(0, 4)" :key="index" xs3>
        <v-card>
          <div class="img-container">
            <a :href="`http://cdn.buldit.be/${image.filePath}`" target="_blank">
              <v-img
                :src="`http://cdn.buldit.be/${image.filePath}`"
                aspect-ratio="1"
              />
            </a>
            <v-btn
              fab
              dark
              small
              color="primary"
              class="img-button"
              @click="deleteImage(image.id)"
              ><v-icon dark>far fa-trash-alt</v-icon></v-btn
            >
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-subheader class="body-warning" v-if="isBodyFilled === false"
      >Body is verplicht!</v-subheader
    >
    <div style="min-height: 200px;">
      <quill-editor
        ref="textEditor"
        v-model="body"
        :options="editorOption"
        style="height: 100px !important;"
      />
    </div>
    <annotation-img-dialog
      :show-dialog="showDialog"
      :carousel-index="imageIndex"
      :images="images"
      @close="showDialog = false"
    />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteAnnotationType from "../annotation-type/VAutocompleteAnnotationType";
import VAutocompleteAnnotationSubType from "../annotation-sub-type/VAutocompleteAnnotationSubType";
import { isNil } from "lodash";
import AnnotationImgDialog from "@/components/annotation/AnnotationImgDialog";
import VUploadImage from "@/components/generic/input/VUploadImage";
import BDatePicker from "@/components/generic/input/dates/BDatePicker";

const { mapComputed } = createNamespacedHelpers("annotation");

export default {
  name: "specific-annotation",
  components: {
    BDatePicker,
    VUploadImage,
    AnnotationImgDialog,
    VAutocompleteAnnotationSubType,
    VAutocompleteAnnotationType
  },
  inject: ["$validator"],
  props: {
    websiteIds: {
      type: Array,
      default() {
        return [];
      }
    },
    articleIds: {
      type: Array,
      default() {
        return [];
      }
    },
    subjectIds: {
      type: Array,
      default() {
        return [];
      }
    },
    keywordIds: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: () => ({
    menu: false,
    showDialog: false,
    imageIndex: null,
    addToWebsite: false,
    editorOption: {
      theme: "snow",
      modules: {
        markdownShortcuts: {},
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],

          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
          ["link"]
        ]
      }
    },
    isTypeLoaded: false,
    reloadSubTypes: false,
    isBodyFilled: false
  }),
  computed: {
    ...mapComputed([
      "id",
      "title",
      "type",
      "subTypes",
      "date",
      "body",
      "keywords",
      "subjects",
      "articles",
      "websites",
      "files",
      "file"
    ]),
    isSpecificKeyword() {
      return this.keywords.length > 0;
    },
    isSpecificSubject() {
      return this.subjects.length > 0;
    },
    isSpecificArticle() {
      return this.articles.length > 0;
    },
    isSpecificWebsite() {
      return this.websites.length > 0;
    },
    article() {
      return this.$store.state.article;
    },
    website() {
      return this.$store.state.website;
    },
    isTypeNull() {
      return isNil(this.type);
    },
    images() {
      if (Array.isArray(this.files)) {
        return this.files.filter(e => {
          const extension = e.fileName ? e.fileName.split(".").pop() : null;
          return (
            extension === "jpg" || extension === "jpeg" || extension === "png"
          );
        });
      } else {
        return [];
      }
    }
  },
  watch: {
    type() {
      this.reloadSubTypes = true;
    },
    addToWebsite(newVal) {
      if (newVal) {
        let websiteIds = [];
        websiteIds.push(this.$store.state.article.model.website);
        this.$store.commit("annotation/setWebsites", websiteIds);
      } else {
        this.$store.commit("annotation/setWebsites", []);
      }
    },
    body: {
      handler(newVal) {
        if (!isNil(newVal) && newVal !== "") {
          this.isBodyFilled = true;
        } else if (newVal === "") {
          this.isBodyFilled = false;
        }
      }
    }
  },
  mounted() {
    this.checkIfSpecific();
  },
  methods: {
    async checkIfSpecific() {
      if (this.websiteIds.length > 0) {
        this.$store.commit("annotation/setType", 2);
        this.$store.commit("annotation/setWebsites", this.websiteIds);
      }
      if (this.articleIds.length > 0) {
        this.$store.commit("annotation/setType", 3);
        this.$store.commit("annotation/setArticles", this.articleIds);
      }
      if (this.keywordIds.length > 0) {
        this.$store.commit("annotation/setType", 4);
        this.$store.commit("annotation/setKeywords", this.keywordIds);
      }
      if (this.subjectIds.length > 0) {
        this.$store.commit("annotation/setType", 5);
        this.$store.commit("annotation/setSubjects", this.subjectIds);
      }
    },
    typeLoaded() {
      this.isTypeLoaded = true;
    },
    setReloadSubTypes(val) {
      this.reloadSubTypes = val;
    },
    openDialog(index) {
      this.showDialog = true;
      this.imageIndex = index;
    },
    deleteImage(fileId) {
      this.$store.dispatch("annotation/deleteImage", {
        annotationId: this.id,
        fileId: fileId
      });
      this.$emit("image-deleted");
    }
  }
};
</script>
<style scoped>
@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg3-custom {
    width: 33.333%;
    max-width: 33.333%;
    flex-basis: 33.333%;
  }
}

p {
  margin-bottom: 5px;
}
.img-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.img-container img {
  width: 100%;
  height: auto;
}

.img-button {
  position: absolute;
  top: 80%;
  left: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  text-align: center;
}

.body-warning {
  color: red;
}
</style>
