<template>
  <v-sheet style="margin-bottom: 20px;">
    <quill-editor
      id="quill-editor"
      ref="textEditor"
      v-model="note"
      class="editor"
      :options="editorOption"
    />
    <v-progress-linear
      v-if="isLoading"
      :indeterminate="true"
      color="secondary"
      style="margin: 0px"
      height="2"
      value="15"
    />
  </v-sheet>
</template>
<script type="text/babel">
import api from "@/api";

const saveNote = api.website.saveNote;

export default {
  name: "website-note",
  components: {},
  props: {},
  data() {
    return {
      queryTimeout: null,
      isLoading: false,
      editableWebsite: null,
      editorOption: {
        theme: "bubble",
        bounds: "#quill-editor",
        placeholder: "Website note...",
        modules: {
          markdownShortcuts: {},
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],

            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
            ["image"],
            ["link"]
          ]
        }
      }
    };
  },
  computed: {
    note: {
      get() {
        return this.$store.getters["website/note"];
      },
      set(newValue) {
        this.$store.commit("website/setNote", newValue);
        clearTimeout(this.queryTimeout);
        this.queryTimeout = setTimeout(() => {
          this.saveNote();
        }, 2000);
      }
    }
  },
  methods: {
    async saveNote() {
      this.isLoading = true;
      await saveNote(this.$store.state.website.model.id, this.note);
      this.isLoading = false;
    }
  }
};
</script>
<style>
.editor {
  border-bottom: 1px solid #b3b3b3;
}
</style>
