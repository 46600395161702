<template>
  <table-overview
    v-if="!showChild"
    title="Websites"
    icon="far fa-fw fa-globe"
    store="websites"
    :headers="headers"
    :actions="actions"
    searchable
    force-initial-sort="last_three_months_visitors"
    descending
    sticky-first-column
  >
    <websites-table-row
      slot="table-row"
      slot-scope="{ item }"
      :website-id="item"
      :timeframe="timeframe"
    />
    <websites-table-filter
      slot="filters"
      store="websites"
      @set-timeframe="setTimeframe"
    />
  </table-overview>
  <router-view v-else />
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import WebsitesTableRow from "../../components/website/WebsitesTableRow";
import WebsitesTableFilter from "../../components/website/WebsitesTableFilter";
import websitesModule from "@/store/modules/websites";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "websites",
  websitesModule
);

export default {
  name: "websites-view",
  components: {
    TableOverview,
    WebsitesTableRow,
    WebsitesTableFilter
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "websites.create" },
        rolesOrPermissions: "website_create"
      }
    ],
    timeframe: "3M"
  }),
  computed: {
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    },
    headers() {
      return [
        { text: "Website", value: "host" },
        { text: "Locales", value: "locales", sortable: false },
        { text: "Articles", sortable: false },
        { text: "Visitors", value: this.visitors },
        { text: "Bounce Rate", value: this.bounceRate },
        { text: "Time On Page", value: this.timeOnPage },
        { text: "Rejection Rate", value: this.rejectionRate },
        { text: "Conversions", value: this.conversions },
        {
          text: "Conversion Rate",
          value: this.conversionRate
        },
        {
          text: "Sold",
          value: this.soldSubmissions
        },
        {
          text: "Unsold",
          value: "unsold_submissions",
          sortable: false
        },
        {
          text: "Sold Ratio",
          value: "sold_submissions_ratio",
          sortable: false
        },
        {
          text: "Profit",
          value: this.profit
        },
        {
          text: "Valid",
          align: "center",
          sortable: false,
          class: "v-table-th--icon"
        },
        {
          text: "Sync",
          align: "center",
          sortable: false,
          class: "v-table-th--icon",
          rolesOrPermissions: "article_sync"
        },
        {
          text: "Details",
          align: "center",
          sortable: false,
          class: "v-table-th--icon",
          rolesOrPermissions: "article_view"
        }
      ];
    },
    timeframeMetricName() {
      if (this.timeframe === "M") {
        return "last_month";
      } else if (this.timeframe === "W") {
        return "last_week";
      } else if (this.timeframe === "3M") {
        return "last_three_months";
      } else if (this.timeframe === "Y") {
        return "last_year";
      } else if (this.timeframe === "ALL") {
        return "all_time";
      } else {
        return null;
      }
    },
    visitors() {
      return this.timeframeMetricName + "_visitors";
    },
    bounceRate() {
      return this.timeframeMetricName + "_bounce_rate";
    },
    timeOnPage() {
      return this.timeframeMetricName + "_time_on_page";
    },
    rejectionRate() {
      return this.timeframeMetricName + "_rejection_rate";
    },
    conversions() {
      return this.timeframeMetricName + "_conversions";
    },
    conversionRate() {
      return this.timeframeMetricName + "_conversion_rate";
    },
    soldSubmissions() {
      return this.timeframeMetricName + "_sold_submissions";
    },
    profit() {
      return this.timeframeMetricName + "_profit";
    }
  },
  methods: {
    setTimeframe(val) {
      this.$store.dispatch(`websites/setSort`, val);
      this.timeframe = val;
    }
  },
  ...overviewLifecycleMethods
};
</script>
