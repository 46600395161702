<template>
  <tr>
    <td>{{ website.host }}</td>
    <td>
      <v-chip v-for="locale in locales" :key="locale.id" small label>
        {{ locale.name }}
      </v-chip>
    </td>
    <td>{{ website.articleCount }}</td>
    <td>{{ visitors }}</td>
    <td>{{ round(bounceRate) }}%</td>
    <td>{{ time(timeOnPage) }}</td>
    <td>{{ round(rejectionRate) }}%</td>
    <td>{{ conversions }}</td>
    <td>{{ round(conversionRate) }}%</td>
    <td>{{ soldSubmissions }}</td>
    <td>{{ unsoldSubmissions }}</td>
    <td>{{ round(soldSubmissionsRatio) }}%</td>
    <td>€{{ round(profit) }}</td>
    <td class="v-table-td--icon">
      <v-tooltip v-if="hasProblems" bottom>
        <v-icon slot="activator" color="warning">
          far fa-fw fa-exclamation-triangle
        </v-icon>
        <span>
          <span v-for="(problem, i) in problems" :key="i">
            {{ problem }}
          </span>
        </span>
      </v-tooltip>
      <v-icon v-else color="success">
        far fa-fw fa-check
      </v-icon>
    </td>
    <td v-if="hasRolesOrPermissions('website_sync')" class="v-table-td--icon">
      <v-btn icon @click="sync">
        <v-icon v-if="isStartingSync || website.isSyncing">
          far fa-fw fa-sync fa-spin
        </v-icon>
        <v-icon v-else-if="website.isSynced">
          far fa-fw fa-sync
        </v-icon>
        <v-icon v-else color="grey">
          far fa-fw fa-sync
        </v-icon>
      </v-btn>
    </td>
    <td v-if="hasRolesOrPermissions('website_view')" class="v-table-td--icon">
      <v-btn
        icon
        :to="{ name: 'websites.show', params: { websiteId: websiteId } }"
      >
        <v-icon>far fa-fw fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID, FIND_BY_IDS } from "@/store/templates/table/getter-types";
import { isNil } from "lodash";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapWebsiteGetters } = createNamespacedHelpers("websites");
const { mapGetters: mapLocaleGetters } = createNamespacedHelpers("locales");

export default {
  name: "websites-overview-row",
  props: {
    websiteId: {
      type: Number,
      required: true
    },
    timeframe: {
      type: String,
      default: "3M"
    }
  },
  data: () => ({
    isStartingSync: false
  }),
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapWebsiteGetters([FIND_BY_ID]),
    ...mapLocaleGetters([FIND_BY_IDS]),
    website() {
      return this[FIND_BY_ID](this.websiteId);
    },
    locales() {
      return this[FIND_BY_IDS](this.website.locales);
    },
    problems() {
      const problems = [];
      if (this.website.isCertificateFaulty) {
        problems.push("Certificate is faulty");
      }
      if (!this.website.isHreflangAvailable) {
        problems.push("Missing hreflang tags.");
      }
      if (this.website.isHreflangMalformed) {
        problems.push("Hreflang tags malformed.");
      }
      if (this.website.isUserAgentSpoofRequired) {
        problems.push("UserAgent spoof required.");
      }
      if (this.website.isWordpressApiBroken) {
        problems.push("WordPress API broken.");
      }
      if (this.website.isWordpressSite && !this.website.hasWordpressApi) {
        problems.push("WordPress site, but has no WP API.");
      }
      return problems;
    },
    hasProblems() {
      return this.problems.length > 0;
    },
    timeframeMetric() {
      return this.$store.getters["websites/timeframeMetricById"](
        this.website.timeframeMetric
      );
    },
    timeframeMetricName() {
      if (this.timeframe === "M") {
        return "lastMonth";
      } else if (this.timeframe === "W") {
        return "lastWeek";
      } else if (this.timeframe === "3M") {
        return "lastThreeMonths";
      } else if (this.timeframe === "Y") {
        return "lastYear";
      } else if (this.timeframe === "ALL") {
        return "allTime";
      } else {
        return null;
      }
    },
    visitors() {
      return this.timeframeMetric[this.timeframeMetricName + "Visitors"];
    },
    bounceRate() {
      return this.timeframeMetric[this.timeframeMetricName + "BounceRate"];
    },
    timeOnPage() {
      return this.timeframeMetric[this.timeframeMetricName + "TimeOnPage"];
    },
    rejectionRate() {
      return this.timeframeMetric[this.timeframeMetricName + "RejectionRate"];
    },
    conversions() {
      return this.timeframeMetric[this.timeframeMetricName + "Conversions"];
    },
    conversionRate() {
      return this.timeframeMetric[this.timeframeMetricName + "ConversionRate"];
    },
    soldSubmissions() {
      return this.timeframeMetric[this.timeframeMetricName + "SoldSubmissions"];
    },
    unsoldSubmissions() {
      return this.conversions - this.soldSubmissions;
    },
    soldSubmissionsRatio() {
      return (this.soldSubmissions / this.conversions) * 100;
    },
    profit() {
      return this.timeframeMetric[this.timeframeMetricName + "Profit"] / 100;
    }
  },
  methods: {
    async sync() {
      this.isStartingSync = true;
      await this.$store.dispatch("websites/sync", {
        websiteId: this.websiteId
      });
      this.isStartingSync = false;
    },
    round(rate) {
      return !isNil(rate) ? rate.toFixed(2) : rate;
    },
    time(time) {
      let date = new Date(null);
      date.setSeconds(time.toString());
      return date.toISOString().substr(11, 8);
    }
  }
};
</script>
