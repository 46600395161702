<template>
  <v-container fluid>
    <v-text-field
      v-model="host"
      v-validate="'required|max:255'"
      label="Url"
      :counter="255"
      data-vv-name="host"
      :error-messages="errors.collect('host')"
      required
    />
    <v-select-locale
      v-model="locales"
      multiple
      data-vv-rules="required|min:1"
      data-vv-name="locales"
      :error-messages="errors.collect('locales')"
    />
    <v-text-field
      v-model="googleTrackingId"
      v-validate="'max:255'"
      label="Google Tracking ID"
      placeholder="UA-012..."
      :counter="255"
      data-vv-name="Google tracking ID"
      :error-messages="errors.collect('googleTrackingId')"
    />
    <v-text-field
      v-model="googleViewId"
      v-validate="'max:255'"
      label="Google View ID"
      placeholder="012345..."
      :counter="255"
      data-vv-name="Google View ID"
      :error-messages="errors.collect('googleViewId')"
    />
    <v-switch label="Is searchable on Buldit.be" v-model="isSearchable" />
  </v-container>
</template>
<script type="text/babel">
import VSelectLocale from "../locale/VSelectLocale";
import { createNamespacedHelpers } from "@/store/helpers";

const { mapComputed } = createNamespacedHelpers("website");

export default {
  name: "website-create",
  inject: ["$validator"],
  components: {
    VSelectLocale
  },
  data: () => ({}),
  computed: {
    ...mapComputed([
      "host",
      "locales",
      "googleTrackingId",
      "googleViewId",
      "isSearchable"
    ])
  }
};
</script>
