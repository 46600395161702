<template>
  <v-chip label>
    <div>
      {{ leadSubjectName }}
      <website-submission-table-row-lead-offer
        v-for="offerId in lead.offers"
        :key="offerId"
        :offer-id="offerId"
      />
    </div>
  </v-chip>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import WebsiteSubmissionTableRowLeadOffer from "@/components/website/WebsiteSubmissionTableRowLeadOffer";

export default {
  name: "website-submission-table-row-lead",
  components: { WebsiteSubmissionTableRowLeadOffer },
  props: {
    leadId: {
      type: Number,
      required: true
    }
  },
  computed: {
    lead() {
      return this.$store.state.website.submissions.lead[this.leadId];
    },
    leadSubject() {
      return this.$store.state.website.submissions.subject[
        this.lead.defaultSubject
      ];
    },
    leadSubjectName() {
      return isNil(this.leadSubject) ? undefined : this.leadSubject.name;
    }
  }
};
</script>
