<template>
  <div>
    <v-layout row style="margin: 0">
      <v-flex xs6>
        <v-layout align-start justify-start row>
          <v-chip
            v-for="(tag, index) in tags"
            :key="index"
            color="primary"
            text-color="white"
            label
          >
            {{ tag.name }}
          </v-chip>
          <v-chip
            color="primary lighten-2"
            text-color="white"
            label
            @click="openDialog"
          >
            <v-icon>edit</v-icon>
          </v-chip>
        </v-layout>
      </v-flex>
      <v-flex xs6>
        <v-layout align-start justify-end>
          <v-chip
            v-for="(sibling, index) in siblings"
            :key="index"
            color="accent"
            label
            ><router-link
              style="color: black; text-decoration: none;"
              :to="{ name: 'websites.show', params: { websiteId: sibling.id } }"
              target="_blank"
              >{{ locale(sibling.locales[0]).code }}</router-link
            >
          </v-chip>
        </v-layout>
      </v-flex>
    </v-layout>
    <website-tag-dialog :show-dialog="showDialog" @close="showDialog = false" />
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import WebsiteTagDialog from "@/components/website/dashboard/WebsiteTagDialog";

const { mapGetters, mapState } = createNamespacedHelpers("website");

export default {
  name: "website-tags",
  components: { WebsiteTagDialog },
  data() {
    return {
      showDialog: false
    };
  },
  computed: {
    ...mapGetters(["type", "tags", "siblings"]),
    ...mapState(["tag"]),
    tagNames() {
      return this.tags.map(tagId =>
        this.tag[tagId] ? this.tag[tagId].name : null
      );
    }
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    locale(id) {
      return this.$store.state.website.locale[id];
    }
  }
};
</script>
