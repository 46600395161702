import api from "@/api";
import { SYNC as DISPATCH_SYNC } from "./action-types";
import { FETCH as DISPATCH_FETCH } from "../../templates/table/action-types";
import tableActions from "../../templates/table/actions";
import { UPDATE_ITEM as COMMIT_UPDATE_ITEM } from "../../templates/table/mutation-types";

const fetchCall = api.website.fetchAll;

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    const result = await tableActions.fetch(context, { fetchCall });

    window.Echo.channel("websites").listen(".website.updated", data => {
      const { entities, result } = api.website.normalize(data);
      context.commit(COMMIT_UPDATE_ITEM, { item: result, entities });
    });

    return result;
  },
  async [DISPATCH_SYNC]({ commit }, { websiteId }) {
    const { entities, result } = await api.website.sync(websiteId);
    commit(COMMIT_UPDATE_ITEM, { item: result, entities });
    return result;
  },
  async setSort({ commit, dispatch }, timeframe) {
    commit("setSort", timeframe);
    dispatch(DISPATCH_FETCH);
  }
};
