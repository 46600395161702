<template>
  <div>
    <v-card color="white">
      <crud-header
        :title="host"
        type="Website"
        store="website"
        icon="far fa-fw fa-globe"
        :tabs="tabs"
      />
    </v-card>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import websiteModule from "@/store/modules/website";
import { mapDetailViewLifecycleMethods } from "../helpers";

const { mapGetters } = createNamespacedHelpers("website");
const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "website",
  websiteModule
);

export default {
  name: "website-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    CrudHeader
  },

  computed: {
    ...mapGetters(["id", "host"]),
    tabs() {
      return [
        {
          name: "Dashboard",
          to: { name: "websites.show", params: { websiteId: this.id } },
          rolesOrPermissions: "website_view"
        },
        {
          name: "Articles",
          to: { name: "websites.articles", params: { websiteId: this.id } },
          rolesOrPermissions: "website_articles_view"
        },
        {
          name: "Submissions",
          to: { name: "websites.submissions", params: { websiteId: this.id } },
          rolesOrPermissions: "website_submissions_view"
        },
        {
          name: "Siteplan",
          to: { name: "websites.siteplan", params: { websiteId: this.id } },
          rolesOrPermissions: "website_siteplan_view"
        },
        {
          name: "Settings",
          to: { name: "websites.settings", params: { websiteId: this.id } },
          rolesOrPermissions: "website_settings_view"
        },
        {
          name: "Siblings",
          to: { name: "websites.siblings", params: { websiteId: this.id } },
          rolesOrPermissions: "website_siblings_view"
        }
      ];
    }
  },
  ...detailViewLifecycleMethods
};
</script>
