<template>
  <div>
    <h4 v-if="title">{{ title }}</h4>
    <v-layout row wrap>
      <v-flex xs12 sm6>
        <b-date-picker
          data-vv-name="from"
          v-model="from"
          :required="isRequired"
          label="From"
          :type="type"
        />
      </v-flex>
      <v-flex xs12 sm6>
        <b-date-picker
          data-vv-name="to"
          v-model="to"
          :required="isRequired"
          label="To"
          :type="type"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script type="text/babel">
import BDatePicker from "@/components/generic/input/dates/BDatePicker";
export default {
  name: "between-date-input",
  components: { BDatePicker },
  inject: ["$validator"],
  props: {
    value: {
      type: Object,
      default: () => ({ from: null, to: null })
    },
    type: {
      type: String,
      optional: true
    },
    bothRequired: {
      //Values both either filled or empty
      type: Boolean,
      default: false
    },
    required: {
      //Always required
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    from: {
      get() {
        return this.model.from;
      },
      set(value) {
        this.model = {
          from: value,
          to: this.to
        };
      }
    },
    to: {
      get() {
        return this.model.to;
      },
      set(value) {
        this.model = {
          from: this.from,
          to: value
        };
      }
    },
    isRequired() {
      return (
        this.required ||
        (this.bothRequired && !!this.model.from !== !!this.model.to)
      );
    }
  }
};
</script>
