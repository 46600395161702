<template>
  <tr>
    <td>{{ article.path }}</td>
    <td>{{ article.title }}</td>
    <td v-if="hasRolesOrPermissions('article_view')" class="v-table-td--icon">
      <v-btn icon :to="{ name: 'articles.show', params: { articleId } }">
        <v-icon>far fa-fw fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { ITEM as GETTER_ITEM } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapWebsiteArticlesGetters } = createNamespacedHelpers(
  "website/articles"
);

export default {
  name: "website-articles-table-row",
  props: {
    websiteId: {
      type: Number,
      required: true
    },
    articleId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapWebsiteArticlesGetters([GETTER_ITEM]),
    article() {
      return this[GETTER_ITEM](this.articleId);
    }
  }
};
</script>
