<template>
  <v-card>
    <div style="height: 300px">
      <vue-apex-charts
        ref="websiteSubjectMap"
        height="90%"
        type="pie"
        :options="options"
        :series="series"
      />
    </div>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import { isNil } from "lodash";
import moment from "moment";
import api from "@/api";
const fetchCallSubjectMap = api.website.stats.fetchSubjectMap;

export default {
  name: "website-subject-map",
  components: {
    vueApexCharts
  },
  props: {
    sibling: {
      type: Number,
      required: false
    },
    title: {
      type: String,
      default: "Subject map"
    },
    date: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      series: [],
      options: {
        chart: {
          type: "pie"
        },
        theme: {
          palette: "palette2" // upto palette10
        },
        legend: {
          position: "right",
          offsetY: 50
        },
        title: {
          text: this.title,
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px"
          }
        },
        labels: []
      },
      data: []
    };
  },
  created() {
    this.fetch();
  },
  watch: {
    date: {
      handler(newVal) {
        if (!isNil(newVal.from) || !isNil(newVal.to)) {
          this.fetch(newVal);
        } else {
          this.fetch();
        }
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    async fetch(date) {
      this.series = [];
      let to = moment().format("YYYY-MM-DD");
      let from = moment()
        .subtract(3, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      if (!isNil(date)) {
        to = isNil(date.to)
          ? moment().format("YYYY-MM-DD")
          : moment(date.to).format("YYYY-MM-DD");
        from = moment(date.from)
          .startOf("month")
          .format("YYYY-MM-DD");
      }
      this.data = await fetchCallSubjectMap(
        isNil(this.sibling) ? this.$store.state.website.model.id : this.sibling,
        {
          date: { from: from, to: to }
        }
      );
      let labels = [];

      this.data.forEach(data => {
        this.series.push(data.submissionCount);
        labels.push(`${data.subjectName}: ${data.submissionCount}`);
      });
      const chart = this.$refs.websiteSubjectMap;
      chart.updateOptions({
        title: { text: `${this.title} (${from} - ${to})` }
      });
      chart.refresh();
      chart.updateOptions({ labels: labels });
    }
  }
};
</script>
