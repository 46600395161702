<template>
  <v-card color="white">
    <crud-header
      :title="host"
      type="Website"
      store="website"
      icon="far fa-fw fa-globe"
      @save-success="
        ({ id }) =>
          $router.push({ name: 'websites.show', params: { websiteId: id } })
      "
    />
    <website-create />
  </v-card>
</template>

<script type="text/babel">
import websiteModule from "@/store/modules/website";
import WebsiteCreate from "../../components/website/WebsiteCreate";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("website");

import { mapCreateViewLifecycleMethods } from "../helpers";
const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "website",
  websiteModule
);

export default {
  name: "website-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    CrudHeader,
    WebsiteCreate
  },
  computed: {
    ...mapGetters(["host"])
  },
  ...createViewLifecycleMethods
};
</script>
