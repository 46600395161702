<template>
  <v-dialog lazy v-model="internalDialog" max-width="500">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-icon>
          far fa-th-large
        </v-icon>
        <v-toolbar-title class="text-uppercase">
          Tags
        </v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-autocomplete-tag
          v-model="tags"
          multiple
          :type="'WEBSITE'"
          :sub-type="subType"
          :initial-load="false"
        />
        <v-layout justify-end>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="secondary"
            @click="addTags"
          >
            Save Tags
          </v-btn>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script type="text/babel">
import VAutocompleteTag from "@/components/tag/VAutocompleteTag";
import { createNamespacedHelpers } from "@/store/helpers";
const { mapComputed } = createNamespacedHelpers("website");

export default {
  name: "website-tag-dialog",
  components: { VAutocompleteTag },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    subType: null,
    loading: false,
    selectedTags: []
  }),
  computed: {
    ...mapComputed("tags"),
    internalDialog: {
      get() {
        return this.showDialog;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  watch: {},
  methods: {
    closeDialog() {},
    async addTags() {
      const websiteId = this.$store.state.website.model.id;
      await this.$store.dispatch("website/addTags", {
        websiteId: websiteId,
        tags: this.tags
      });
      this.$emit("close");
    }
  }
};
</script>
