var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Articles","icon":"far fa-fw fa-globe","store":"website/articles","headers":[
    { text: 'Path', value: 'path' },
    { text: 'Title', value: 'title' },
    {
      text: 'Details',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'article_view'
    }
  ],"searchable":"","force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('website-articles-table-row',{attrs:{"website-id":_vm.id,"article-id":item}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }