<template>
  <v-autocomplete
    v-model="internalValue"
    slot-scope="{ data, isLoading }"
    v-validate="dataVvRules"
    :search-input.sync="searchInput"
    :items="items"
    :loading="isLoading"
    :disabled="disabled"
    :readonly="isReadOnly"
    :multiple="multiple"
    :label="multiple ? 'Types' : 'type'"
    :item-text="itemText"
    :item-value="itemValue"
    :data-vv-name="dataVvName"
    :error-messages="errorMessages"
  />
</template>

<script type="text/babel">
import api from "@/api";

const fetchFunction = api.annotationType.search;

export default {
  name: "v-autocomplete-annotation-type",
  inject: ["$validator"],
  props: {
    value: {
      type: [Number, Array],
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: "id"
    },
    itemText: {
      type: String,
      default: "name"
    },
    dataVvRules: {
      type: String,
      required: false
    },
    dataVvName: {
      type: String,
      required: false
    },
    errorMessages: {
      type: Array,
      required: false
    }
  },
  data: () => {
    return {
      entity: "annotation-type",
      fetchCall: fetchFunction,
      initialLoad: true,
      data: null,
      isLoading: false,
      items: [],
      searchInput: null,
      queryTimeout: null,
      dataLoaded: false
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", Object.is(newValue, undefined) ? null : newValue);
      }
    },
    isDisabled() {
      return this.disabled || this.loading;
    },
    isReadOnly() {
      return this.disabled || this.loading;
    }
  },
  created() {
    if (this.initialLoad) {
      this.getDataFromApi();
    }
  },
  methods: {
    async getDataFromApi() {
      clearTimeout(this.queryTimeout);
      this.isLoading = true;
      this.data = await this.fetchCall({
        filters: this.filter
      });
      this.items = this.items
        .filter(item => this.internalValue.includes(item.id))
        .concat(this.data);
      this.isLoading = false;
      this.$emit("data-loaded");
    }
  }
};
</script>
