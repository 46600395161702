<template>
  <v-autocomplete
    v-model="internalValue"
    slot-scope="{ data, isLoading }"
    v-validate="dataVvRules"
    chips
    small-chips
    :items="items"
    :loading="isLoading"
    :disabled="disabled"
    :readonly="isReadOnly"
    :multiple="multiple"
    :item-text="itemText"
    :item-value="itemValue"
    :data-vv-name="dataVvName"
    :error-messages="errorMessages"
    :label="label ? label : multiple ? 'Tags' : 'Tag'"
  />
</template>

<script type="text/babel">
import { isArray } from "lodash";

import api from "@/api";
const fetchFunction = api.tag.search;

export default {
  name: "v-autocomplete-tag",
  props: {
    subType: {
      type: Number,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    initialLoad: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, Array],
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: "id"
    },
    itemText: {
      type: String,
      default: "name"
    },
    dataVvRules: {
      type: String,
      required: false
    },
    dataVvName: {
      type: String,
      required: false
    },
    errorMessages: {
      type: Array,
      required: false
    }
  },
  data: () => {
    return {
      fetchCall: fetchFunction,
      filter: {},
      data: null,
      isLoading: false,
      items: [],
      searchInput: null,
      queryTimeout: null
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", Object.is(newValue, undefined) ? null : newValue);
      }
    },
    isDisabled() {
      return this.disabled || this.loading;
    },
    isReadOnly() {
      return this.disabled || this.loading;
    }
  },
  beforeMount() {
    this.filter.isFilterable = 1;
    this.setType();
  },
  watch: {
    type(newVal) {
      this.filter.type = newVal;
      this.getDataFromApi();
    },
    subType(newVal) {
      this.filter.subType = newVal;
      this.getDataFromApi();
    }
  },
  methods: {
    setType() {
      this.filter.type = this.type;
      this.filter.subType = this.subType;
      this.getDataFromApi();
    },
    async getDataFromApi() {
      this.items = [];
      clearTimeout(this.queryTimeout);
      this.filter.search = this.searchInput;
      this.filter.ids = null;
      this.isLoading = true;
      this.data = await this.fetchCall({
        filters: this.filter
      });
      if (isArray(this.internalValue)) {
        this.items = this.items
          .filter(item => this.internalValue.includes(item.id))
          .concat(this.data);
      } else {
        this.items = this.items
          .filter(item => this.internalValue === item.id)
          .concat(this.data);
      }
      this.isLoading = false;
    }
  }
};
</script>
