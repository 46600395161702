<template>
  <v-card>
    <div class="chart">
      <vue-apex-charts
        ref="websiteTotalConversionRate"
        height="100%"
        type="line"
        :options="options"
        :series="series"
      />
    </div>
    <div class="tw-grid tw-grid-cols-2">
      <div class="tw-p-4">
        Average conversion rate: <br />
        <b>{{ round(averageData.totalConversionRate) }}</b>
      </div>
      <div class="tw-p-4 tw-grid tw-justify-end tw-text-right">
        Average last year: <br />
        <b>{{ round(lastYearAverageData.totalConversionRate) }}</b>
      </div>
    </div>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";

import { isNil, cloneDeep, groupBy, uniq } from "lodash";
import moment from "moment";

export default {
  name: "website-total-conversion-rate",
  components: {
    vueApexCharts
  },
  props: {
    stats: {
      type: Array,
      default: () => []
    },
    lastYearStats: {
      type: Array,
      default: () => []
    },
    averageData: {
      type: Object,
      default: () => {}
    },
    lastYearAverageData: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: "Total conversion rate"
    },
    interval: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      series: [
        {
          name: "Total Conversion Rate",
          type: "area",
          data: []
        },
        {
          name: "Last year Total Conversion Rate",
          type: "line",
          data: []
        }
      ],
      chartAnnotations: { points: [] },
      clickableClasses: []
    };
  },
  computed: {
    annotations() {
      return this.$store.getters["website/annotations/entities"];
    },
    options() {
      return {
        chart: {
          id: isNil(this.title) ? "tcr" : this.title,
          group: "metrics",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#2E6FB4"
        },
        stroke: {
          width: 2,
          dashArray: [0, 8]
        },
        title: {
          text: this.title,
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#fff"
          }
        },
        tooltip: {
          // eslint-disable-next-line
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            const valueDiff =
              series[0][dataPointIndex] - series[1][dataPointIndex];

            const persentageDiff =
              (valueDiff / series[1][dataPointIndex]) * 100;
            let isPositive = false;
            if (valueDiff > 0) {
              isPositive = true;
            } else {
              isPositive = false;
            }
            return (
              '<div class="custom-tooltip">' +
              '<span class="toolbar-text-info">' +
              "Conversion rate: " +
              (series[0][dataPointIndex] === undefined
                ? series[0][dataPointIndex]
                : series[0][dataPointIndex].toFixed(2)) +
              "%" +
              (isPositive
                ? '<span class="positive-text">'
                : '<span class="negative-text">') +
              (isPositive ? " +" : " -") +
              (persentageDiff === undefined
                ? persentageDiff
                : persentageDiff.toFixed(2)) +
              "%" +
              "</span>" +
              '<span class="last-year-stat">' +
              " (" +
              (series[1][dataPointIndex] === undefined
                ? series[1][dataPointIndex]
                : series[1][dataPointIndex].toFixed(2)) +
              "%)" +
              "</span>" +
              "</span>" +
              "</div>"
            );
          }
        },
        colors: ["#FFCD00", "#FFF"],
        legend: {
          labels: {
            colors: "#FFF"
          }
        },
        xaxis: {
          type: "datetime",
          labels: {
            style: {
              colors: "#fff"
            },
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            tickAmount: 5,
            labels: {
              style: {
                color: "#fff"
              },
              formatter: value => {
                return `${this.round(value)}%`;
              },
              minWidth: 0
            },
            min: 0,
            minWidth: 0
          }
        ],
        annotations: this.chartAnnotations
      };
    }
  },
  watch: {
    stats: {
      handler(newVal) {
        this.series.forEach(e => {
          e.data = [];
        });
        newVal.forEach(e => {
          this.series[0].data.push([
            new Date(e.date).getTime(),
            e.totalConversionRate,
            e.date
          ]);
        });
        this.$refs.websiteTotalConversionRate.refresh();
      },
      deep: true
    },
    lastYearStats: {
      handler(newVal) {
        let mappedVal = cloneDeep(newVal);
        mappedVal = mappedVal.map(data => {
          data.date = moment(data.date)
            .add(1, "years")
            .format(this.interval === "monthly" ? "YYYY-MM" : "YYYY-MM-DD");
          return data;
        });
        this.series[1].data = [];

        this.series[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          const equivalentLastYearIndex = mappedVal.findIndex(
            lastYear => data[0] === new Date(lastYear.date).getTime()
          );
          if (!isNil(equivalentLastYear)) {
            this.series[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.totalConversionRate,
              newVal[equivalentLastYearIndex].date
            ]);
          } else {
            this.series[1].data.push([data[0], 0.0]);
          }
        });
        this.$refs.websiteTotalConversionRate.refresh();
        this.applyAnnotations();
      },
      deep: true
    }
  },
  methods: {
    round(rate) {
      return !isNil(rate) ? rate.toFixed(2) : rate;
    },
    applyAnnotations() {
      this.clickableClasses = [];
      this.chartAnnotations.points = [];
      if (this.interval === "monthly") {
        //group by month
        const groupedAnnotations = groupBy(this.annotations, function(
          annotation
        ) {
          return annotation.date.substring(0, annotation.date.length - 3);
        });
        // we check if there are any annotation that could be located in the timeframe of the chart
        Object.entries(groupedAnnotations).forEach(([key, value]) => {
          this.series.forEach(serie => {
            const location = serie.data.filter(data => data[2] === key);
            if (!isNil(location)) {
              if (!isNil(location[0])) {
                this.addPointAnnotation(location, value);
              }
            }
          });
        }, this);
      } else {
        //handle by day
        const groupedAnnotations = groupBy(this.annotations, function(
          annotation
        ) {
          return annotation.date;
        });
        Object.entries(groupedAnnotations).forEach(([key, value]) => {
          this.series.forEach(serie => {
            const location = serie.data.filter(data => data[2] === key);
            if (!isNil(location)) {
              if (!isNil(location[0])) {
                this.addPointAnnotation(location, value);
              }
            }
          });
        }, this);
      }
      // After creating the annotations we look them up and add a click event listener
      this.$refs.websiteTotalConversionRate.refresh();
      // the chart and annotations have to be fully rendered so we can not execute this immediately after adding the annotations
      setTimeout(() => {
        this.applyClickHandlersToAnnotations();
      }, 2000);
    },
    applyClickHandlersToAnnotations() {
      this.clickableClasses = uniq(this.clickableClasses);
      this.clickableClasses.forEach(className => {
        const point = document.getElementsByClassName(className);
        point[0].addEventListener("mouseover", () => {
          this.hoverIn(className);
        });
        point[0].addEventListener("mouseout", () => {
          this.hoverOut(className);
        });
        point[0].addEventListener("click", () => {
          this.clicked(className);
        });
      });
    },
    addPointAnnotation(location, annotation) {
      const labels = annotation.map(
        annotation =>
          `${annotation.date}: ${annotation.subTypes
            .map(subType => subType.title)
            .join(", ")}`
      );

      const pointAnnotation = {
        x: location[0][0],
        y: location[0][1],
        label: {
          text: labels.join(" | "),
          offsetY: -1000,
          style: { cssClass: `label ${location[0][2]}-conversion` }
        },
        marker: { size: 6, cssClass: `point ${location[0][2]}-conversion` }
      };
      this.chartAnnotations.points.push(pointAnnotation);

      this.clickableClasses.push(`${location[0][2]}-conversion`);
    },
    // eslint-disable-next-line no-unused-vars
    hoverIn(className) {
      // eslint-disable-next-line no-unused-vars
      const point = document.getElementsByClassName(`point ${className}`)[0];
      point.setAttribute("r", 9);
    },
    // eslint-disable-next-line no-unused-vars
    hoverOut(className) {
      // eslint-disable-next-line no-unused-vars
      const point = document.getElementsByClassName(`point ${className}`)[0];
      point.setAttribute("r", 6);
    },
    clicked(className) {
      // if there is no group containing the annotation text we create one
      const group = document.getElementById(`group-${className}`);
      if (isNil(group)) {
        // get the text element from apexcharts and remove the Y offset
        const textSvg = document.getElementsByClassName(
          `label ${className}`
        )[0];
        textSvg.setAttribute("y", 0);
        // get the bounding box of the text element we use this to define the height of the background text
        const textSvgRect = textSvg.getBBox();
        // create the group element we use to wrap the text and bg rect in
        const group = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "g"
        );
        group.setAttribute("id", `group-${className}`);

        // create the rect we will use as the bg for the text
        let rect = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "rect"
        );
        // prevent the annotation label to be out the screen when it is at the start of the chart
        const isAnnotationAtStart = textSvg.getAttribute("x") < 50;
        if (isAnnotationAtStart) {
          textSvg.setAttribute("text-anchor", "start");
        }
        const rectAttributes = {
          x: isAnnotationAtStart
            ? textSvg.getAttribute("x") - 5
            : textSvg.getAttribute("x") - textSvgRect.width / 2 - 5,
          y: textSvg.getAttribute("y") - 10 - 4,
          height: textSvgRect.height + 8,
          width: textSvgRect.width + 10
        };
        // use the text bounding box to define the size and position of our new rect.
        // the text is positioned in the center of our anchor so we divide the x choordinates in half
        // the +x is to get some padding around the text
        rect.setAttribute("x", rectAttributes.x);
        rect.setAttribute("y", rectAttributes.y);
        rect.setAttribute("width", rectAttributes.width);
        rect.setAttribute("height", rectAttributes.height);
        rect.setAttribute("fill", "white");
        rect.setAttribute("text-anchor", "middle");
        textSvg.parentNode.insertBefore(group, textSvg);
        group.appendChild(rect);
        group.appendChild(textSvg);
      } else {
        // move the group in and out of vision on clicks
        if (isNil(group.getAttribute("transform"))) {
          group.setAttribute("transform", "translate(0, 200)");
        } else {
          group.removeAttribute("transform");
        }
      }
    }
  }
};
</script>
<style scoped>
.custom-tooltip {
  background-color: white;
  padding: 10px;
}
.toolbar-text-info {
  font-weight: bold;
}
.positive-text {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}
.negative-text {
  color: red;
  font-weight: bold;
  margin-left: 10px;
}
.last-year-stat {
  font-size: 11px;
  font-weight: 100;
  color: #878787;
}
b {
  font-size: 18px;
}
.chart {
  height: 200px;
}
</style>
