<template>
  <div>
    <transition-group name="fade" mode="out-in">
      <div v-show="!isLoadingStats" key="data">
        <v-layout>
          <v-flex class="mx-4" xs12>
            <website-total-conversion-rate
              :stats="stats"
              :last-year-stats="lastYearStats"
              :average-data="averageData"
              :last-year-average-data="lastYearAverageData"
              :interval="interval"
            />
            <template v-for="(siblingId, index) in selectedSiblings">
              <website-total-conversion-rate
                v-if="showSibling(siblingId)"
                :key="index"
                :title="`${sibling(siblingId).host}`"
                :stats="siblingStats[siblingId].stats"
                :last-year-stats="siblingStats[siblingId].lastYearStats"
                :interval="interval"
                :average-data="siblingStats[siblingId].averageData"
                :last-year-average-data="
                  siblingStats[siblingId].lastYearAverageData
                "
              />
            </template>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex class="mx-4" xs12>
            <website-traffic-graph
              :stats="stats"
              :last-year-stats="lastYearStats"
              :average-data="averageData"
              :last-year-average-data="lastYearAverageData"
              :interval="interval"
            />
            <template v-for="(siblingId, index) in selectedSiblings">
              <website-traffic-graph
                v-if="showSibling(siblingId)"
                :key="index"
                :title="`${sibling(siblingId).host}`"
                :stats="siblingStats[siblingId].stats"
                :last-year-stats="siblingStats[siblingId].lastYearStats"
                :interval="interval"
                :average-data="siblingStats[siblingId].averageData"
                :last-year-average-data="
                  siblingStats[siblingId].lastYearAverageData
                "
              />
            </template>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex class="mx-4" xs12>
            <website-total-conversions-stats
              :stats="stats"
              :last-year-stats="lastYearStats"
              :average-data="averageData"
              :last-year-average-data="lastYearAverageData"
              :interval="interval"
            />
            <template v-for="(siblingId, index) in selectedSiblings">
              <website-total-conversions-stats
                v-show="showSibling(siblingId)"
                :key="index"
                :title="`${sibling(siblingId).host}`"
                :stats="siblingStats[siblingId].stats"
                :last-year-stats="siblingStats[siblingId].lastYearStats"
                :interval="interval"
                :average-data="siblingStats[siblingId].averageData"
                :last-year-average-data="
                  siblingStats[siblingId].lastYearAverageData
                "
              />
            </template>
          </v-flex>
        </v-layout>
        <v-flex xs4 class="mx-4">
          <between-date-input v-model="dateInput" />
        </v-flex>
        <v-layout row>
          <v-flex class="mx-4" xs4>
            <website-subject-map
              :selected-siblings="selectedSiblings"
              :date="dateInput"
            />
          </v-flex>
          <v-flex
            class="mx-4"
            v-for="(siblingId, index) in selectedSiblings"
            xs4
            :key="index"
          >
            <website-subject-map
              v-if="showSibling(siblingId)"
              :key="index"
              :title="`${sibling(siblingId).host}`"
              :sibling="siblingId"
              :date="dateInput"
            />
          </v-flex>
        </v-layout>
      </div>
      <div
        key="loading"
        v-if="isLoadingStats"
        class="tw-grid tw-grid-cols-1 tw-col-span-full tw-content-end tw-justify-center tw-h-32 tw-m-6 tw-p-0"
      >
        <h2 class="tw-flex tw-justify-center tw-align-center loading-info">
          ...Statistics are loading
        </h2>
        <v-progress-linear
          class="tw-m-0"
          :indeterminate="true"
          color="secondary"
        />
      </div>
    </transition-group>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import Vue from "vue";
import moment from "moment";
import { isNil } from "lodash";
import WebsiteTotalConversionsStats from "@/components/website/dashboard/stats/WebsiteTotalConversions";
import api from "@/api";
import WebsiteTrafficGraph from "@/components/website/dashboard/stats/WebsiteTrafficGraph";
import WebsiteTotalConversionRate from "../../components/website/dashboard/stats/WebsiteTotalConversionRate";
import WebsiteSubjectMap from "../../components/website/dashboard/stats/WebsiteSubjectMap";
import BetweenDateInput from "../../components/generic/input/dates/BetweenDateInput";

const { mapGetters } = createNamespacedHelpers("website");

const fetchCall = api.website.stats.fetchTimeSeries;
const fetchCallAverages = api.website.stats.fetchAverages;

export default {
  name: "website-total-stats-view",
  components: {
    BetweenDateInput,
    WebsiteSubjectMap,
    WebsiteTotalConversionRate,
    WebsiteTrafficGraph,
    WebsiteTotalConversionsStats
  },
  props: {
    selectedSiblings: {
      type: Array,
      default: () => []
    },
    interval: {
      type: String,
      required: true
    }
    // annotations: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      stats: [],
      lastYearStats: [],
      averageData: {},
      lastYearAverageData: {},
      siblingStats: [],
      dateInput: {},
      isLoadingStats: false,
      fetchedSiblings: []
    };
  },
  computed: {
    ...mapGetters(["website", "type"]),
    annotations() {
      return this.website ? this.website.annotations : [];
    },
    now() {
      return moment().format("DD-MM-YYYY");
    },
    oneYearAgo() {
      return moment()
        .subtract(1, "years")
        .format("DD-MM-YYYY");
    },
    siblings() {
      return this.$store.getters["website/websiteSiblings"];
    },
    thisYearStartDate() {
      return moment()
        .subtract(1, "years")
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    thisYearEndDate() {
      return moment().format("YYYY-MM-DD");
    },
    lastYearStartDate() {
      return moment()
        .subtract(2, "years")
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    lastYearEndDate() {
      return moment()
        .subtract(1, "years")
        .endOf("month")
        .format("YYYY-MM-DD");
    },
    dailyStartDate() {
      return moment()
        .subtract(3, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    lastYearDailyStartDate() {
      return moment()
        .subtract(1, "years")
        .subtract(3, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    }
  },
  beforeMount() {
    this.fetch();
  },
  watch: {
    interval(newVal) {
      if (newVal === "monthly") {
        this.loadMonthlyStatistics();
      } else {
        this.fetch();
        if (this.selectedSiblings.length > 0) {
          this.fetchSiblingStats(this.selectedSiblings);
        }
      }
    },
    fab(val) {
      this.tooltips = false;
      this.tooltipsDisabled = false;
      val &&
        setTimeout(() => {
          this.tooltips = true;
          this.$nextTick(() => (this.tooltipsDisabled = true));
        }, 100);
    },
    selectedSiblings(newVal, oldVal) {
      let difference = newVal.filter(x => !oldVal.includes(x))[0];

      if (!isNil(difference)) {
        if (this.selectedSiblings.length > 0) {
          this.fetchSiblingStats(this.selectedSiblings);
        }
      }
    }
  },
  methods: {
    async loadMonthlyStatistics() {
      await this.fetch();
      if (this.selectedSiblings.length > 0) {
        await this.fetchSiblingStats(this.selectedSiblings);
      }
    },
    sibling(id) {
      return this.$store.getters["website/sibling"](id);
    },
    createAnnotationDialog() {
      this.$store.commit("website/openAnnotationDialog", true);
    },
    async fetch() {
      this.isLoadingStats = true;
      const from =
        this.interval === "monthly"
          ? this.thisYearStartDate
          : this.dailyStartDate;
      const lastYearFrom =
        this.interval === "monthly"
          ? this.lastYearStartDate
          : this.lastYearDailyStartDate;
      this.stats = await fetchCall(this.$store.state.website.model.id, {
        date: { from: from, to: this.thisYearEndDate },
        interval: this.interval
      });
      this.lastYearStats = await fetchCall(this.$store.state.website.model.id, {
        date: { from: lastYearFrom, to: this.lastYearEndDate },
        interval: this.interval
      });
      this.averageData = await fetchCallAverages(
        this.$store.state.website.model.id,
        {
          date: { from: from, to: this.thisYearEndDate },
          interval: this.interval
        }
      );
      this.lastYearAverageData = await fetchCallAverages(
        this.$store.state.website.model.id,
        {
          date: { from: lastYearFrom, to: this.lastYearEndDate },
          interval: this.interval
        }
      );
      this.isLoadingStats = false;
    },
    async fetchSiblingStats(websiteId) {
      this.isLoadingStats = true;
      Vue.set(this.siblingStats, websiteId, {});
      Vue.set(this.siblingStats[websiteId], "stats", []);
      Vue.set(this.siblingStats[websiteId], "lastYearStats", []);
      Vue.set(this.siblingStats[websiteId], "averageData", {});
      Vue.set(this.siblingStats[websiteId], "lastYearAverageData", {});

      const from =
        this.interval === "monthly"
          ? this.thisYearStartDate
          : this.dailyStartDate;
      const lastYearFrom =
        this.interval === "monthly"
          ? this.lastYearStartDate
          : this.lastYearDailyStartDate;

      const stats = await fetchCall(websiteId, {
        date: { from: from, to: this.thisYearEndDate },
        interval: this.interval
      });
      const lastYearStats = await fetchCall(websiteId, {
        date: { from: lastYearFrom, to: this.lastYearEndDate },
        interval: this.interval
      });
      const averageData = await fetchCallAverages(websiteId, {
        date: { from: from, to: this.thisYearEndDate },
        interval: this.interval
      });
      const lastYearAverageData = await fetchCallAverages(websiteId, {
        date: { from: lastYearFrom, to: this.lastYearEndDate },
        interval: this.interval
      });
      Vue.set(this.siblingStats, websiteId, {});
      Vue.set(this.siblingStats[websiteId], "stats", stats);
      Vue.set(this.siblingStats[websiteId], "lastYearStats", lastYearStats);
      Vue.set(this.siblingStats[websiteId], "averageData", averageData);
      Vue.set(
        this.siblingStats[websiteId],
        "lastYearAverageData",
        lastYearAverageData
      );
      this.isLoadingStats = false;
      this.fetchedSiblings.push(websiteId);
    },
    showSibling(id) {
      if (!isNil(this.siblingStats[id])) {
        return !isNil(this.siblingStats[id].stats);
      } else {
        return false;
      }
    }
  }
};
</script>
<style scoped>
.date {
  text-align: center;
  color: darkgray;
}
.loading-info {
  font-size: 24px;
  font-weight: 300;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
